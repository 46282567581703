import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PregnancyQuestions from 'components/Fragebogen/PregnancyQuestions';
import DynamicQuestions from 'components/Fragebogen/DynamicQuestions';
import InformationSheet from 'components/Fragebogen/InformationSheet';
import { useQuery } from '@tanstack/react-query';
import http from 'components/util/http';
import ProgressBar from 'styles/ProgressBar';
import usePatient from 'components/Navigation/usePatient';
import { useOnboardingNavigation } from 'providers/onboarding-navigation';

export default function Fragebogen() {
  const { step, setStep } = useOnboardingNavigation();
  const navigate = useNavigate();
  const { data } = usePatient();
  const { data: questionaire } = useQuery({
    queryKey: ['questionaire-result'],
    queryFn: async () => await http.get('/medical/patient-survey/'),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (questionaire === undefined) return;
    const step = questionaire.results.length ? 7 : 0;
    setStep(step);
  }, [questionaire]);

  if (!questionaire || !data) return <></>;

  if (data.eligible_for_treatment !== 'maybe') {
    navigate('/');
  }

  return (
    <div className="flex flex-col items-center justify-center py-5 mb-10">
      <div className="w-80 m-auto">
        <ProgressBar max={7} current={step} />
      </div>
      <PregnancyQuestions
        className={step === 0 ? '' : 'hidden'}
        onContinue={(patient) => {
          if (patient.is_pregnant || patient.is_breastfeeding) {
            navigate('/');
          } else {
            setStep(1);
          }
        }}
      />
      <DynamicQuestions
        className={step > 0 && step < 7 ? '' : 'hidden'}
        currentStep={step}
        onContinue={() => setStep(step + 1)}
        onSubmit={() => {
          window.scrollTo(0, 0);
          setStep(7);
        }}
      />
      <InformationSheet className={step === 7 ? '' : 'hidden'} onContinue={() => navigate('/')} />
    </div>
  );
}

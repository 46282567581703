import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/documents.svg';
import { ReactComponent as SpeechBubbleIcon } from 'assets/icons/speech_bubble.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { NavLink, Link } from 'react-router-dom';
import { useAuthContext } from 'components/util/useAuth';
import useLocalizedImages from '../../hooks/useLocalizedImages';

export const routes = [
  {
    title: 'Therapie',
    to: '/therapie',
    Icon: CalendarIcon,
  },
  {
    title: 'Dokumente',
    to: '/dokumente',
    Icon: DocumentIcon,
  },
  {
    title: 'Service',
    to: '/service',
    Icon: SpeechBubbleIcon,
  },
  {
    title: 'Einstellungen',
    to: '/einstellungen',
    Icon: SettingsIcon,
  },
];

export default function Sidebar() {
  const { logout } = useAuthContext();
  const { logo } = useLocalizedImages();

  return (
    <aside className="grid grid-rows-sidebar h-screen sticky top-0 border-r bg-swopa-primary-white border-swopa-border pl-8">
      <Link to="/therapie" className="mt-10 mb-20 pr-8">
        <img className="w-[116px]" src={logo} alt="" />
      </Link>
      <nav>
        <ul>
          {routes.map(({ title, to, Icon }, index) => (
            <li key={index} className="w-full ">
              <NavLink
                className={(navData) =>
                  `flex flex-row justify-start space-x-3 items-center py-3 -ml-8 pl-8 hover:bg-swopa-hover-grey cursor-pointer  ${
                    navData.isActive
                      ? 'text-swopa-accent-green border-r border-swopa-accent-green'
                      : 'text-swopa-primary-dark-blue'
                  }`
                }
                to={to}
              >
                <Icon className="w-4 h-4" />
                <span>{title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div
        className="flex flex-row items-center space-x-3 cursor-pointer text-swopa-primary-dark-blue mb-14 w-fit hover:text-swopa-accent-green"
        onClick={logout}
      >
        <LogoutIcon className="w-4 h-4" />
        <span>Abmelden</span>
      </div>
    </aside>
  );
}

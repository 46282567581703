import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
// @ts-ignore
import { Analytics } from '@vercel/analytics/react';

const isProd = process.env.REACT_APP_BRANCH === 'MAIN';
const queryClient = new QueryClient();
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
          <Analytics mode={isProd ? 'production' : 'development'} />
        </QueryClientProvider>
      </React.StrictMode>
    </HelmetProvider>
  </BrowserRouter>,
);

import { useEffect } from 'react';
import { useBookingUrl } from '../providers/BookingUrl';
import { useNavigate } from 'react-router-dom';

export default function Termin() {
  const { bookingUrl } = useBookingUrl();
  const navigate = useNavigate();

  useEffect(() => {
    if (bookingUrl === null) {
      navigate('/therapie/verschreibungen');
    }
    const wrapper = document.getElementById('iframe-wrapper');
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    console.log(bookingUrl);
    iframe.src = bookingUrl || '';
    wrapper?.appendChild(iframe);
  }, []);

  return null;
}

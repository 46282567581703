import { useLocation, useNavigate } from 'react-router-dom';
import { FormikErrors, Form, Formik } from 'formik';
import { string } from 'yup';
import Title from 'components/util/Title';
import Input from 'styles/FormikInput';
import Button from 'styles/Button';
import { useAuthContext } from 'components/util/useAuth';
import { useNotificationContext } from 'components/Notification';
import Layout from './Layout';
import { useState } from 'react';

interface LoginFormValues {
  email: string;
  password: string;
}

interface MyFormProps {
  initialEmail?: string;
  setEmail: (mail: string) => void;
}

const LoginForm = ({ initialEmail, setEmail }: MyFormProps) => {
  const auth = useAuthContext();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { clearNotification, showNotification } = useNotificationContext();

  return (
    <Formik
      initialValues={{
        email: initialEmail || '',
        password: '',
      }}
      validate={async (values: LoginFormValues) => {
        let errors: FormikErrors<LoginFormValues> = {};

        if (!values.email) {
          errors.email = 'Bitte geben Sie ihre E-Mail-Adresse ein';
        } else if (values.email) {
          await string()
            .email()
            .validate(values.email)
            .catch(() => {
              errors.email = 'Ungültige E-Mail-Adresse';
            });
        }

        if (!values.password) {
          errors.password = 'Bitte geben Sie Ihr Passwort ein';
        }

        setEmail(values.email);
        return errors;
      }}
      onSubmit={async (values, { setErrors }) => {
        clearNotification();
        auth.login(values.email.toLowerCase(), values.password).then(
          () => {
            navigate(location?.state?.from?.pathname || '/therapie');
          },
          () => {
            setErrors({ email: ' ' });
            showNotification({
              type: 'error',
              text: 'Diese Kombination von Benutzername und Passwort ist uns leider nicht bekannt oder das Konto wurde noch nicht aktiviert. Bitte versuchen Sie es erneut.',
            });
          },
        );
      }}
    >
      {(formikProps) => (
        <Form onSubmit={formikProps.handleSubmit}>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder="E-Mail-Adresse"
            error={!!(formikProps.touched.email && formikProps.errors.email)}
            errorMessage={formikProps.errors.email}
            className="mb-2"
          />
          <Input
            id="password"
            type="password"
            name="password"
            placeholder="Passwort"
            error={!!(formikProps.touched.password && formikProps.errors.password)}
            errorMessage={formikProps.errors.password}
            className="mb-4"
          />
          <Button type="submit" disabled={formikProps.isSubmitting}>
            ANMELDEN
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default function Login() {
  const [mail, setMail] = useState('');
  const navigate = useNavigate();

  return (
    <Layout>
      <Title title="Login" />
      <h2 className="mb-8">Anmelden</h2>
      <LoginForm setEmail={setMail} />
      <div className="flex justify-between mt-2">
        <div className="flex flex-col space-y-1">
          <a
            className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green"
            href={`/passwort-vergessen?email=${mail}`}
          >
            Passwort vergessen?
          </a>
          <a
            className="text-swopa-secondary-grey cursor-pointer hover:text-swopa-accent-green"
            href="/aktivierung-erneut-senden"
          >
            Aktivierungsmail erneut senden
          </a>
        </div>
        <a
          className="text-swopa-primary-dark-blue cursor-pointer hover:text-swopa-accent-green"
          href="/register/personal"
        >
          Konto erstellen
        </a>
      </div>
    </Layout>
  );
}
